/**
 * options can take three parameters, they are optional:
 * offset - number, optional, default 0. Indentation from the element to which we scroll. A negative value is longer from to the block.
 * smooth - boolean, optional, default true. Slow or fast scrolling
 * beforeScrolling - function, optional, default empty, beforeScrolling is a function that will run before scrolling, useful for unfreezing scrolling, closing the mobile menu, etc.
 * afterScrolling - function, optional, default empty, afterScrolling is a function that will run after scrolling.
 *
 * Add the data-button-scroll-anchor attributes to the buttons,
 * and put the selector of the block to which you need to scroll in the value.
 * Example: data-button-scroll-anchor="[data-review-apps]"
 *
 * Usage:
 *
 * initScrollToAnchor({
        offset: 200,
        smooth: false,
        beforeScrolling: function () {
            console.log('beforeScrolling');
        },
        afterScrolling: function () {
            console.log('afterScrolling');
        },
    });
 *
 * or without params
 *
 * initScrollToAnchor();
 *
 * Bugs with scrolling can occur in the following cases:
 * 1. You set the zoom property in the styles, which will not allow you to adequately calculate the scroll to the element.
 * 2. If you added loading lazy to the pictures, but did not set the height of the pictures. Set the height to the images and it will work fine.
 * */

import { scrollToElem } from '../helper-functions/scrollToElem';

export const initScrollToAnchor = (options = {}) => {
    // VARS
    const buttons = document.querySelectorAll('[data-button-scroll-anchor]');
    if (!buttons.length) return;
    const { beforeScrolling = () => {}, afterScrolling = () => {}, offset = 0, smooth = true } = options;

    buttons.forEach((button) => {
        button.addEventListener('click', function (event) {
            beforeScrolling();
            if (this.tagName.toLowerCase() === 'a') {
                event.preventDefault();
                history.pushState('', '', this.href);
            }

            const targetSelector = button.getAttribute('data-button-scroll-anchor');
            scrollToElem(targetSelector, offset, smooth);

            const targetElement = document.querySelector(targetSelector);
            if (targetElement) {
                const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
                const checkScrollCompletion = () => {
                    if (Math.abs(window.pageYOffset - targetPosition) < 1) {
                        afterScrolling();
                    } else {
                        requestAnimationFrame(checkScrollCompletion);
                    }
                };
                requestAnimationFrame(checkScrollCompletion);
            }
        });
    });
};
